import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true,  // Set pixel's autoConfig
  debug: false, // Enable logs
};

export const initFacebookPixel = (pixelId) => {
  if (!pixelId) {
    console.error('Facebook Pixel ID is invalid or not provided.');
    return;
  }
  ReactPixel.init(pixelId, options);
  ReactPixel.pageView();
};
